import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "item" ]

  connect() {
  }

  disconnect(){
  }

  //https://web.dev/drag-and-drop/?gclid=CjwKCAjw9LSSBhBsEiwAKtf0n9_SSRsqOL55q2yFiAkxuFdChwgd__mK150xi4kumJGItZ2nkz5OwRoCGC0QAvD_BwE
  handleDragStart(event) {
    this.draggedElement = event.currentTarget;
    event.currentTarget.classList.add('opacity-50');

    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', event.currentTarget.outerHTML);
  }

  handleDragEnd(event) {
    this.itemTargets.forEach((item) => {
      item.classList.remove('opacity-50', 'border-4', 'border-indigo-600', 'border-dashed');
    });
  }

  handleDragOver(event) {
    if (this.draggedElement !== event.currentTarget) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      event.currentTarget.classList.add('border-4', 'border-indigo-600', 'border-dashed');
      return false;
    }
  }

  handleDragEnter(event) {
    if (this.draggedElement !== event.currentTarget) {
      event.currentTarget.classList.add('border-4', 'border-indigo-600', 'border-dashed');
    }
  }

  handleDragLeave(event) {
    event.currentTarget.classList.remove('border-4', 'border-indigo-600', 'border-dashed');
  }
  handleDrop(event) {
    event.stopPropagation(); // stops the browser from redirecting.
    if (this.draggedElement !== event.currentTarget) {
      this.draggedElement.outerHTML = event.currentTarget.outerHTML;
      event.currentTarget.outerHTML = event.dataTransfer.getData('text/html');
      this.updatePosition(event.currentTarget.dataset.surveyId, event.currentTarget.id.split("_")[1], this.draggedElement.dataset.position);
      this.updatePosition(event.currentTarget.dataset.surveyId, this.draggedElement.id.split("_")[1], event.currentTarget.dataset.position);
      this.draggedElement = null;
    }
    this.itemTargets.forEach((item) => {
      item.classList.remove('opacity-50', 'border-4', 'border-indigo-600', 'border-dashed');
    });
    return false;
  }

  updatePosition(survey_id, question_id, new_position) {
    Rails.ajax({
      type: "PUT",
      url: "/admin/surveys/" + survey_id + "/questions/" + question_id,
      dataType: "json",
      //https://github.com/rails/rails/issues/31507#issuecomment-556278656
      beforeSend(xhr, options) {
        xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
        // Workaround: add options.data late to avoid Content-Type header to already being set in stone
        // https://github.com/rails/rails/blob/master/actionview/app/assets/javascripts/rails-ujs/utils/ajax.coffee#L53
        options.data = JSON.stringify({question: {position: new_position}})
        return true
      },
      //data: {question: {position: new_position}},
      success: (data) => {
        document.getElementById("question_" + question_id).outerHTML =  data.record;
      }
    })
  }

}