import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Chartkick from "chartkick";

export default class extends Controller {

  static targets = [ "filter", "wordCloud" ]

  connect() {
    if (this.hasWordCloudTarget) {
      Rails.ajax({
        type: "GET",
        url: this.wordCloudTarget.dataset.url,
        dataType: "json",
        success: (data) => {
          // 1. Create a new array from data
          let orderedData = data.map((x) => x);
          // 2. Order it by number of articles each tag has
          orderedData.sort(function(a, b) {
            return a.count - b.count;
          });
          orderedData = orderedData.reverse();
          // 3. Get a value for the tag with the most articles
          let highestValue = orderedData[0].count;
          // 4. Create a list item for each result from data.
          data.forEach((result) => this.handleResult(result, highestValue));
          // 5. Append all the tags to the tags element.
          // if (data.length > 1) {
            //this.wordCloudTarget.appendChild(this.fragment);
          // }
        }
      });
    }
  }

  onChangeChartType(event) {
    event.currentTarget.closest("form").submit();
  }

  onGetAnswersChart(event) {
    Rails.ajax({
      type: "GET",
      url: this.filterTarget.dataset.url + "?" + this.filterTarget.dataset.key + "=" + this.filterTarget.value,
      dataType: "json",
      success: (data) => {
        Chartkick.charts[this.filterTarget.nextElementSibling.children[0].id].updateData(data);
      }
    });
  } 

  //https://css-tricks.com/create-a-tag-cloud-with-some-simple-css-and-even-simpler-javascript/
  onGetWordCloud() {
    if (this.filterTarget.value) {
      this.wordCloudTarget.innerHTML = null;
      Rails.ajax({
        type: "GET",
        url: this.filterTarget.dataset.url + "?" + this.filterTarget.dataset.key + "=" + this.filterTarget.value,
        dataType: "json",
        success: (data) => {
          // 1. Create a new array from data
          let orderedData = data.map((x) => x);
          // 2. Order it by number of articles each tag has
          orderedData.sort(function(a, b) {
            return a.count - b.count;
          });
          orderedData = orderedData.reverse();
          // 3. Get a value for the tag with the most articles
          let highestValue = orderedData[0].count;
          // 4. Create a list item for each result from data.
          data.forEach((result) => this.handleResult(result, highestValue));
          // 5. Append all the tags to the tags element.
          // if (data.length > 1) {
            //this.wordCloudTarget.appendChild(this.fragment);
          // }
        }
      });
      //this.wordCloudTarget.src = this.filterTarget.dataset.url + "?" + this.filterTarget.dataset.key + "=" + this.filterTarget.value;
    }
  }

  handleResult(result, highestValue) {
    // Set our variables
    let numberOfArticles = result.count;
    let name = result.text;
    let link = result.href;
    let maxFontSizeForTag = 6;
    let fontSize = numberOfArticles / highestValue * this.maxFontSizeForTag;
    fontSize = +fontSize.toFixed(2);
    
    // Make sure our font size will be at least 1em
    if (fontSize <= 1) {
      fontSize = 1;
    } else {
      fontSize = fontSize;
    }
    let fontSizeProperty = `${fontSize}em`;

    // Then, create a list element for each tag and inline the font size.
    let tag = document.createElement("li");
    tag.classList.add("tag");
    tag.innerHTML = `<a class="tag__link" href="${link}" style="font-size: ${fontSizeProperty}">${name} (${numberOfArticles})</a>`;

    // Append each tag to the fragment
    this.wordCloudTarget.appendChild(tag);
  }

}