import Sortable from 'stimulus-sortable'

export default class extends Sortable {
  //https://github.com/stimulus-components/stimulus-sortable/issues/12#issuecomment-1029777270
  end ({ item, newIndex }) {
    super.end({ item, newIndex })
    var elems = item.parentElement.getElementsByClassName("position")
    for (var i = 0; i < elems.length; i++) {
      elems[i].innerText = i + 1;
    }
  }
}