import { Controller } from "stimulus";

export default class extends Controller {

  static targets = [ "message", "buttonText" ]

  onCopy(event) {
    if (!navigator.clipboard) {
      // Clipboard API not available
      return;
    }
    const text = this.messageTarget.value;
    try {
      navigator.clipboard.writeText(text);
      let button = this.buttonTextTarget;
      button.innerText = "Copied!";
      button.closest('button').className = "btn-purple"
      setTimeout(function () {
        button.innerText = "Copy";
        button.closest('button').className = "btn-white"
      }, 3000);
    } catch (err) {
      console.error("Failed to copy!", err);
    }
  }
}