import { Controller } from "stimulus";
import Rails from "@rails/ujs";
import Chartkick from "chartkick";

export default class extends Controller {

  static targets = [ "questionOne", "questionOneOptions", "questionOneHint",  "questionTwo", "questionTwoResults", "questionTwoHint" ]

  connect() {
   
  }

  onQuestionOneSelect(event) {
    if (event.currentTarget.value) {
      Rails.ajax({
        type: "GET",
        url: "/admin/surveys/" + this.element.dataset.surveySlug + "/questions/" + event.currentTarget.value, //this.questionOneTarget.value,
        dataType: "json",
        success: (data) => {
          this.questionOneOptionsTarget.innerHTML = data.options;
          this.questionOneHintTarget.innerHTML = null;
          this.questionTwoHintTarget.innerHTML = null;
          this.questionTwoResultsTarget.innerHTML = "<p class='text-gray-400 text-sm py-4'>Please chose one or more option(s) on the left first.</p>";
        }
      });
    }
  }

  onQuestionOneOptionChange(event) {
    if (event.currentTarget.value) {
      if (this.questionTwoTarget.value) {
        /*Rails.ajax({
          type: "GET",
          url: "/admin/surveys/" + this.element.dataset.surveySlug + "/attempts?q1=" + this.questionOneTarget.value + "&opt=" + event.currentTarget.value + "&q2=" + this.questionTwoTarget.value, //this.questionOneTarget.value,
          dataType: "json",
          success: (data) => {
            this.questionOneHintTarget.innerHTML = data.hint;
            this.questionTwoResultsTarget.innerHTML = data.chart;
            Chartkick.charts["chart-1"].redraw();
          }
        });*/
        event.currentTarget.closest("form").submit();
      } else {
        Rails.ajax({
          type: "GET",
          url: "/admin/surveys/" + this.element.dataset.surveySlug + "/questions/" + this.questionOneTarget.value + "?opt=" + event.currentTarget.value,
          dataType: "json",
          success: (data) => {
            this.questionOneHintTarget.innerHTML = data.question_one_hint;
          }
        });
      }
    }
  }

  onQuestionTwoSelect(event) {
    //if (event.currentTarget.value && this.questionOneTarget.value && this.questionOneOptionsTarget.value) {
      /*Rails.ajax({
        type: "GET",
        url: "/admin/surveys/" + this.element.dataset.surveySlug + "/attempts?q1=" + this.questionOneTarget.value + "&opt=" + this.questionOneOptionTarget.value + "&q2=" + event.currentTarget.value,
        dataType: "json",
        success: (data) => {
          this.questionOneHintTarget.innerHTML = data.hint;
          this.questionTwoResultsTarget.innerHTML = data.chart;
          Chartkick.charts["chart-1"].redraw();
        }
      });*/
      event.currentTarget.closest("form").submit();
    //}
  }

  onSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.questionOneHintTarget.innerHTML = data.question_one_hint;
    this.questionTwoResultsTarget.innerHTML = data.chart;
    this.questionTwoHintTarget.innerHTML = data.question_two_hint;
    Chartkick.charts["chart-1"].redraw();
  }
}